import jpg from '../../../img/jpg/*.jpg'
import webp from '../../../img/webp/*.webp'

import { t } from '../translations'

const jpgs: Record<string, string> = jpg as any
const webps: Record<string, string> = webp as any

export const portraits = [
  {
    id: 1,
    jpg: jpgs['PO_01'],
    webp: webps['PO_01'],
    description: `Deset`,
    tags: [t.images.categories.portraits],
  },
  {
    id: 2,
    jpg: jpgs['PO_06'],
    webp: webps['PO_06'],
    description: `Ima li nade`,
    tags: [t.images.categories.portraits],
  },
  {
    id: 3,
    jpg: jpgs['PO_04'],
    webp: webps['PO_04'],
    description: `Još jedan dim`,
    tags: [t.images.categories.portraits],
  },
  {
    id: 4,
    jpg: jpgs['PO_05'],
    webp: webps['PO_05'],
    description: `Tamo daleko`,
    tags: [t.images.categories.portraits],
  },
  {
    id: 5,
    jpg: jpgs['PO_03'],
    webp: webps['PO_03'],
    description: `Monahinja`,
    tags: [t.images.categories.portraits],
  },
  {
    id: 6,
    jpg: jpgs['PO_07'],
    webp: webps['PO_07'],
    description: `Sećam se...`,
    tags: [t.images.categories.portraits],
  },
  {
    id: 7,
    jpg: jpgs['PO_02'],
    webp: webps['PO_02'],
    description: `Sećanja`,
    tags: [t.images.categories.portraits],
  },
  {
    id: 11,
    jpg: jpgs['PO_11'],
    webp: webps['PO_11'],
    description: `Vizija`,
    tags: [t.images.categories.portraits],
  },
  {
    id: 12,
    jpg: jpgs['PO_13'],
    webp: webps['PO_13'],
    description: `Teška vremena`,
    tags: [t.images.categories.portraits],
  },
  {
    id: 13,
    jpg: jpgs['PO_12'],
    webp: webps['PO_12'],
    description: `Deda Sava`,
    tags: [t.images.categories.portraits],
  },
  {
    id: 14,
    jpg: jpgs['PO_14'],
    webp: webps['PO_14'],
    description: `Takav život`,
    tags: [t.images.categories.portraits],
  },
  {
    id: 15,
    jpg: jpgs['PO_15'],
    webp: webps['PO_15'],
    description: `Pogled`,
    tags: [t.images.categories.portraits],
  },
  {
    id: 16,
    jpg: jpgs['PO_20'],
    webp: webps['PO_20'],
    description: `Bol`,
    tags: [t.images.categories.portraits],
  },
  {
    id: 17,
    jpg: jpgs['PO_21'],
    webp: webps['PO_21'],
    description: `Kovitlac ljubavi`,
    tags: [t.images.categories.portraits],
  },
  {
    id: 18,
    jpg: jpgs['PO_22'],
    webp: webps['PO_22'],
    description: `Slušaj moju pesmu`,
    tags: [t.images.categories.portraits],
  },
  {
    id: 19,
    jpg: jpgs['PO_23'],
    webp: webps['PO_23'],
    description: `Plela mi ih majka`,
    tags: [t.images.categories.portraits],
  },
]
