import React from 'react'
import { calcWinsize } from '../utils/window-size'
import GridFx from '../grid-fx'
import { getByTag as getImagesByTag, IImage } from '../data/images'
import { Categories, getById as getCategoryById } from '../data/categories'
import { Lightbox } from './photography/lightbox'
import { t } from '../data/translations'
import { Image } from '../components/image'
import { gridArea, gridAreaDesktop } from './photography/grid-area'
import { imagesLoaded } from '../utils/images-loaded'
import { useHashChange } from '../lib/use-router'
import { isRoute } from '../lib/router'

export const Photography = () => {
  const [currentCategory, setCurrentCategory] = React.useState(0)
  const [categories] = React.useState(Categories)
  const [images, setImages] = React.useState<IImage[]>([])
  const [clickedSlide, setClickedSlide] = React.useState<number | null>(null)
  const [min768, setMin768] = React.useState(false)
  const [isPhotographyPage, setPhotographyPage] = React.useState(false)

  const { hash } = useHashChange()

  const gridRef = React.useRef<HTMLDivElement>(null)

  const handleCategoryClick: (number: number) => void = (id) => {
    setCurrentCategory(id)
  }

  React.useEffect(() => {
    const cat = getCategoryById(currentCategory)?.name
    if (cat) {
      setImages(getImagesByTag(cat))
    }
  }, [currentCategory])

  React.useEffect(() => {
    document.body.classList.add('loading')

    const fn = () => {
      if (gridRef.current) {
        // Initialize the grid instance.
        new GridFx(gridRef.current)

        const imageElements = gridRef.current.querySelectorAll<HTMLImageElement>(
          '.grid__item-img img',
        )

        // Preload all the images in the page..
        imagesLoaded(imageElements)
          ?.catch(console.error)
          .finally(() => {
            document.body.classList.remove('loading')
          })
      }
    }
    fn()
  }, [calcWinsize, images, imagesLoaded, gridRef.current, currentCategory])

  React.useEffect(() => {
    const listener = (r: MediaQueryListEvent) => setMin768(r.matches)
    window.matchMedia(`(min-width: 768px)`).addListener(listener)

    return () => window.matchMedia(`(min-width: 768px)`).removeListener(listener)
  }, [])

  React.useEffect(() => {
    const isFocusable = Boolean(!clickedSlide && isRoute('photography'))
    setPhotographyPage(isFocusable), [hash]
  })

  const handleSlideChange = (n: number) => {
    setClickedSlide(n)
  }

  return (
    <section className="content content--photography">
      <Lightbox images={images} openSlide={clickedSlide!} onSlideChange={handleSlideChange} />
      <aside className="content--category">
        <h3>{t.pages.photography.categories.title}</h3>
        <ul className="category__list">
          {categories.map((category) => {
            const categoryItemClass = `category__list-item${
              category.id === currentCategory ? ' category__list-item--current' : ''
            }`
            const categoryItemTitle = `${category.name} (${category.count})`

            return (
              <li key={category.id} className={categoryItemClass}>
                <a
                  tabIndex={isPhotographyPage ? 0 : -1}
                  onClick={() => handleCategoryClick(category.id)}
                  href={location.hash}
                >
                  {categoryItemTitle}
                </a>
              </li>
            )
          })}
        </ul>
      </aside>
      <div ref={gridRef} className="grid content--grid">
        {images.map((image, index) => {
          const gridAreaValue = min768 ? gridAreaDesktop(index) : gridArea(index)
          return (
            <a
              tabIndex={isPhotographyPage ? 0 : -1}
              onClick={() => handleSlideChange(index)}
              key={image.id}
              href={location.hash}
              className="grid__item"
              style={{ gridArea: gridAreaValue }}
            >
              <div className="grid__item-wrap">
                <div className="grid__item-img">
                  <Image
                    src={image.webp}
                    fallback={image.jpg}
                    type={`image/webp`}
                    alt={image.description}
                  />
                </div>
              </div>
              <h3 className="grid__item-title">{image.description}</h3>
            </a>
          )
        })}
      </div>
    </section>
  )
}
