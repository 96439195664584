import React from 'react'
import '../../style/lightbox'
import { Images } from '../../data/images'
import { useSwipeable, EventData } from 'react-swipeable'
import { Image } from '../../components/image'
import { openFullscreen, closeFullscreen, isFullScreenMode } from '../../utils/full-screen'
import { useHashChange } from '../../lib/use-router'

interface ILightbox {
  images: typeof Images
  openSlide: number
  onSlideChange: (n: number) => void
}

export const Lightbox = (props: ILightbox) => {
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(props.openSlide)
  const { hash } = useHashChange()
  const isModalOpen = currentSlideIndex !== null

  React.useEffect(() => {
    const isOpen = props.openSlide !== null
    setCurrentSlideIndex(props.openSlide)
    document.body.style.overflow = isOpen ? 'hidden' : 'unset'
  }, [props.openSlide])

  React.useEffect(() => {
    const changeListener: (this: ScreenOrientation, ev: Event) => any = (ev) => {
      if (!isFullScreenMode && (ev.currentTarget as any).angle !== 0) {
        openFullscreen(document.querySelector('.lightbox-modal')!)
      } else {
        closeFullscreen()
      }
    }

    if (isModalOpen) {
      window.screen.orientation?.addEventListener('change', changeListener)
    } else {
      if (isFullScreenMode) {
        closeFullscreen()
      }
    }

    return () => window.screen.orientation?.removeEventListener('change', changeListener)
  }, [isModalOpen])

  React.useEffect(() => {
    if (isModalOpen) {
      props.onSlideChange(null as any)
    }
  }, [hash])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    switch (event.keyCode) {
      case 13:
        event.preventDefault()
        ;(event.target as HTMLDivElement).click()
        break
      case 27:
        event.preventDefault()
        closeModal()
        break
      case 37:
        event.preventDefault()
        plusSlides(-1)
        break
      case 38:
        event.preventDefault()
        setCurrentSlideIndex(0)
        break
      case 39:
        event.preventDefault()
        plusSlides(1)
        break
      case 40:
        event.preventDefault()
        setCurrentSlideIndex(props.images.length - 1)
      default:
        break
    }
  }

  const handleSwipe = (event: EventData) => {
    if (event.deltaX > 50) {
      plusSlides(1)
    } else if (event.deltaX < -50) {
      plusSlides(-1)
    }
  }

  const swipe = useSwipeable({ onSwiped: handleSwipe })

  // close the Modal
  const closeModal = () => {
    props.onSlideChange(null as any)
  }

  // next / previous controls
  const plusSlides = (n: number) => {
    setCurrentSlideIndex(getSlideIndex(n))
  }

  const getSlideIndex = (n: number) => {
    const nextIndex = currentSlideIndex + n
    const lastIndex = props.images.length - 1

    return nextIndex > lastIndex ? 0 : nextIndex < 0 ? lastIndex : nextIndex
  }

  const getSlideClass = (index: number) => {
    const slideCount = props.images.length

    if (index === currentSlideIndex) {
      return ' active-slide'
    } else if (
      (currentSlideIndex === 0 && index === slideCount - 1) ||
      index === currentSlideIndex - 1
    ) {
      return ' prev-slide'
    } else if (
      (currentSlideIndex === slideCount - 1 && index === 0) ||
      (currentSlideIndex !== null && index === currentSlideIndex + 1)
    ) {
      return ' next-slide'
    } else {
      return ''
    }
  }

  React.useEffect(() => {
    document.body.classList[isModalOpen ? 'add' : 'remove']('modal-open')
  }, [isModalOpen])

  const prevImage = props.images?.[getSlideIndex(-1)]
  const nextImage = props.images?.[getSlideIndex(1)]

  return (
    <div onKeyDown={handleKeyDown} className={`lightbox-modal${isModalOpen ? ' show' : ' hide'}`}>
      <span className="close cursor">
        <a tabIndex={4} className="close-button" onClick={() => closeModal()} />
      </span>
      <div className="numbertext">{`${currentSlideIndex + 1} / ${props.images.length}`}</div>
      <div className="modal-content">
        <ul className="lightbox-slides" {...swipe}>
          {props.images.map((image, index) => {
            return (
              <li key={image.id} className={`lightbox-slide${getSlideClass(index)}`}>
                <Image
                  src={image.webp}
                  fallback={image.jpg}
                  type={`image/webp`}
                  alt={image.description}
                  draggable={false}
                />
                <div className="caption-container">
                  <p dangerouslySetInnerHTML={{ __html: image.description }} />
                </div>
              </li>
            )
          })}
          {/* <!-- Next/previous controls --> */}
          <nav className="nav-fillpath">
            <a tabIndex={2} className="prev" onClick={() => plusSlides(-1)}>
              <span className="icon-wrap" />
              <h3>{prevImage?.description}</h3>
            </a>
            <a tabIndex={1} className="next" onClick={() => plusSlides(1)}>
              <span className="icon-wrap" />
              <h3>{nextImage?.description}</h3>
            </a>
          </nav>
        </ul>

        {/* <!-- Thumbnail image controls --> */}
        <ul
          className="thumbnail-list"
          style={{ justifyContent: props.images.length > 10 ? 'flex-start' : 'center' }}
        >
          {props.images.map((image, index) => (
            <li
              key={image.id}
              className={`thumbnail-item${index === currentSlideIndex ? ' active' : ''}`}
            >
              <a
                tabIndex={3}
                href={location.hash}
                ref={(el) => index === currentSlideIndex && el?.focus()}
                onClick={() => setCurrentSlideIndex(index)}
              >
                <Image
                  src={image.webp}
                  fallback={image.jpg}
                  type={`image/webp`}
                  alt={image.description}
                />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
