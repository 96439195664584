function getYearsOld(birthday: Date) {
  return Math.abs(new Date(Date.now() - birthday.getTime()).getUTCFullYear() - 1970)
}

const translations = {
  meta: {
    name: `Jan Valo`,
    address: `Novi Sad, Serbia`,
    tel: `tel. +381 64 31 49 659`,
  },
  menu: {
    photography: `galerija`,
    contact: `kontakt`,
  },
  pages: {
    home: {
      description: /* html */ `Dobrodošli na moj website. Moje ime je <mark>Jan</mark> i ja sam fotograf iz
        <mark>Novog Sada</mark>. Imam ${getYearsOld(
          new Date(1964, 11, 29),
        )} godina, srećno sam oženjen i ponosan sam roditelj jedne Dragane.
        <br />
        <br />
        <cite>Fotografiju posmatram celom, ako je doživim... što znači da ona ima uživanje u trenutku stvaranja pre <mark>škljoca</mark>, za vreme zamrzavanja <mark>trenutka</mark> i na kraju kad ostane <mark>živa</mark> kako za mene, a tako isto i za posmatrača...</cite>
        <br />
        Ljubav prema fotografiji datira jos iz osnovne škole, gde sam bio član foto-sekcije i po prvi put se susreo sa foto-aparatom, filmom i mračnom komorom. Intenzivnije se vraćam toj ljubavi u 2005-oj godini kada sam sebi nabavio svoj prvi kompaktni foto-aparat...
        <br />
        <br />
        Narednih godina postao sam član Fotografskog kluba Vojvodina, Grupe 32 "Čuvari trenutaka", Foto Saveza Srbije i Foto-Komune "24. Kadar". Osim toga, osvojio sam mnoštvo priznanja i nagrada, a takođe bio i organizator nekoliko sopstvenih izložbi.
      `,
    },
    photography: {
      categories: {
        title: `kategorije`,
      },
    },
    contact: {
      title: `Kontaktiraj me`,
      form: {
        name: `Ime`,
        email: `Email`,
        message: `Poruka`,
        send: `Pošalji`,
      },
    },
  },
  images: {
    categories: {
      portraits: `portreti`,
      'black-white': `crno-bele`,
      streetLife: `ulični život`,
      architecture: `arhitektura`,
      nature: `priroda`,
      nude: `nude`,
    },
  },
}

export const t = translations
