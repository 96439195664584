// @TODO: move to React
// The grid item obj.
export default class GridItem {
  DOM: {
    el: HTMLDivElement
    wrap: HTMLDivElement | null
    title: HTMLDivElement | null
  }
  rects: {
    el: ClientRect | DOMRect | null
    wrap: ClientRect | DOMRect | null
  }
  layoutFn: () => void

  constructor(el: HTMLDivElement) {
    this.DOM = { el, wrap: null, title: null }
    // Image wrap.
    this.DOM.wrap = this.DOM.el.querySelector('.grid__item-wrap')
    // Item´s title
    this.DOM.title = this.DOM.el.querySelector('.grid__item-title')
    this.rects = { el: null, wrap: null }
    this.layoutFn = () => this.calcDimentions()
    // Get the size and position of both the main item and the wrap elements.
    this.calcDimentions()
    this.initEvents()
  }
  calcDimentions() {
    this.rects = {
      el: this.DOM.el.getBoundingClientRect(),
      wrap: this.DOM.wrap?.getBoundingClientRect() || null,
    }
  }
  initEvents() {
    window.addEventListener('resize', () => this.layoutFn())
  }
}
