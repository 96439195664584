import React from 'react'
import { t } from '../data/translations'

export const Footer = () => {
  const url = `${window.location.protocol}//${window.location.host}`

  return (
    <footer className="footer">
      <div className="footer__left">
        © {new Date().getFullYear()} <a href={url}>{t.meta.name}</a>
      </div>
      <div className="footer__right">
        <div className="footer__column socials">
          <a href="https://1x.com/member/janvalo" target="blank">
            1x
          </a>
          <a href="https://www.facebook.com/jan.valo.71" target="blank">
            Facebook
          </a>
          <a href="https://www.instagram.com/janvalo64" target="blank">
            Instagram
          </a>
        </div>
        <div className="footer__column">
          <span className="address">
            {t.meta.name}
            <br />
            {t.meta.address}
            <br />
            {t.meta.tel}
          </span>
        </div>
      </div>
    </footer>
  )
}
