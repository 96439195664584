import React from 'react'

interface Props extends React.ImgHTMLAttributes<any> {
  src: string
  fallback: string
  type: string
}

export const Image = ({ src, fallback, type = 'image/webp', ...delegated }: Props) => {
  return (
    <picture>
      <source srcSet={src} type={type} />
      <img src={fallback} {...delegated} />
    </picture>
  )
}
