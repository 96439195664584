import React from 'react'
import { Header } from './components/header'
import { Footer } from './components/footer'
import { About } from './pages/about'
import { Photography } from './pages/photography'
import { Contact } from './pages/contact'
import { useHashChange } from './lib/use-router'
import { getCurrentRoute } from './lib/router'

export const App = () => {
  const { hash } = useHashChange()
  const currentRoute = getCurrentRoute()

  React.useEffect(() => {
    const photography = document.querySelector('nav.menu > .menu__item--photography')
    const contact = document.querySelector('nav.menu > .menu__item--contact')
    const about = document.querySelector('h2.title > a')

    switch (currentRoute) {
      case 'about':
        ;(about as any).click()
        break
      case 'photography':
        ;(photography as any).click()
        break
      case 'contact':
        ;(contact as any).click()
        break
      default:
        break
    }
  }, [hash])

  React.useEffect(() => {
    const fn = () => {
      document.documentElement.className = 'js'
      const supportsCssVars = function () {
        let e
        let t = document.createElement('style')
        return (
          (t.innerHTML = 'root: { --tmp-var: bold; }'),
          document.head.appendChild(t),
          (e = !!window.CSS?.supports?.('font-weight', 'var(--tmp-var)')),
          t.parentNode?.removeChild(t),
          e
        )
      }

      supportsCssVars() || alert('Please use modern browser that supports CSS Variables.')
    }
    fn()
  }, [])

  return (
    <main>
      <Header />
      <About />
      <Photography />
      <Contact />
      <Footer />
    </main>
  )
}
