import { portraits } from './portraits'
import { blackWhite } from './black-white'
import { nature } from './nature'
import { streetLife } from './street-life'
import { architecture } from './architecture'
import { nude } from './nude'

export function getByTag(tag: string) {
  return Images.filter((item) => item.tags.indexOf(tag) !== -1)
}

export interface IImage {
  id: number
  jpg: string
  webp: string
  description: string
  tags: string[]
}

export const Images: IImage[] = [
  ...portraits,
  ...blackWhite,
  ...nature,
  ...streetLife,
  ...architecture,
  ...nude,
]
