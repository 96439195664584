import jpg from '../../../img/jpg/*.jpg'
import webp from '../../../img/webp/*.webp'

import { t } from '../translations'

const jpgs: Record<string, string> = jpg as any
const webps: Record<string, string> = webp as any

export const nude = [
  {
    id: 1,
    jpg: jpgs['NU_01'],
    webp: webps['NU_01'],
    description: `Shot`,
    tags: [t.images.categories.nude],
  },
  {
    id: 2,
    jpg: jpgs['NU_02'],
    webp: webps['NU_02'],
    description: `In-out`,
    tags: [t.images.categories.nude],
  },
  {
    id: 3,
    jpg: jpgs['NU_03'],
    webp: webps['NU_03'],
    description: `Touch`,
    tags: [t.images.categories.nude],
  },
  {
    id: 4,
    jpg: jpgs['NU_04'],
    webp: webps['NU_04'],
    description: `Start`,
    tags: [t.images.categories.nude],
  },
  {
    id: 5,
    jpg: jpgs['NU_05'],
    webp: webps['NU_05'],
    description: `Beauty`,
    tags: [t.images.categories.nude],
  },
  {
    id: 6,
    jpg: jpgs['NU_06'],
    webp: webps['NU_06'],
    description: `Otpor I`,
    tags: [t.images.categories.nude],
  },
  {
    id: 7,
    jpg: jpgs['NU_07'],
    webp: webps['NU_07'],
    description: `Otpor II`,
    tags: [t.images.categories.nude],
  },
  {
    id: 8,
    jpg: jpgs['NU_08'],
    webp: webps['NU_08'],
    description: `Power`,
    tags: [t.images.categories.nude],
  },
  {
    id: 9,
    jpg: jpgs['NU_09'],
    webp: webps['NU_09'],
    description: `U sebi`,
    tags: [t.images.categories.nude],
  },
  {
    id: 10,
    jpg: jpgs['NU_10'],
    webp: webps['NU_10'],
    description: `Teskoba`,
    tags: [t.images.categories.nude],
  },
  {
    id: 11,
    jpg: jpgs['NU_11'],
    webp: webps['NU_11'],
    description: `Oslonac`,
    tags: [t.images.categories.nude],
  },
  {
    id: 12,
    jpg: jpgs['NU_12'],
    webp: webps['NU_12'],
    description: `Silueta`,
    tags: [t.images.categories.nude],
  },
  {
    id: 13,
    jpg: jpgs['NU_13'],
    webp: webps['NU_13'],
    description: `Body`,
    tags: [t.images.categories.nude],
  },
  {
    id: 14,
    jpg: jpgs['NU_14'],
    webp: webps['NU_14'],
    description: `Čudna šuma`,
    tags: [t.images.categories.nude],
  },
  {
    id: 15,
    jpg: jpgs['NU_15'],
    webp: webps['NU_15'],
    description: `Sloboda`,
    tags: [t.images.categories.nude],
  },
  {
    id: 16,
    jpg: jpgs['NU_16'],
    webp: webps['NU_16'],
    description: `Goli život`,
    tags: [t.images.categories.nude],
  },
  {
    id: 17,
    jpg: jpgs['NU_17'],
    webp: webps['NU_17'],
    description: `Ka vrhu`,
    tags: [t.images.categories.nude],
  },
  {
    id: 18,
    jpg: jpgs['NU_20'],
    webp: webps['NU_20'],
    description: `Pogled`,
    tags: [t.images.categories.nude],
  },
  {
    id: 19,
    jpg: jpgs['NU_21'],
    webp: webps['NU_21'],
    description: `Novi život`,
    tags: [t.images.categories.nude],
  },
  {
    id: 20,
    jpg: jpgs['NU_22'],
    webp: webps['NU_22'],
    description: `Dance`,
    tags: [t.images.categories.nude],
  },
  {
    id: 21,
    jpg: jpgs['NU_23'],
    webp: webps['NU_23'],
    description: `Izgubljena`,
    tags: [t.images.categories.nude],
  },
  {
    id: 22,
    jpg: jpgs['NU_24'],
    webp: webps['NU_24'],
    description: `Ne, nema predaje`,
    tags: [t.images.categories.nude],
  },
  {
    id: 23,
    jpg: jpgs['NU_25'],
    webp: webps['NU_25'],
    description: `Teret`,
    tags: [t.images.categories.nude],
  },
]
