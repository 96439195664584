import jpg from '../../../img/jpg/*.jpg'
import webp from '../../../img/webp/*.webp'

import { t } from '../translations'

const jpgs: Record<string, string> = jpg as any
const webps: Record<string, string> = webp as any

export const streetLife = [
  {
    id: 1,
    jpg: jpgs['SL_01'],
    webp: webps['SL_01'],
    description: `Pod metalnim svodom`,
    tags: [t.images.categories.streetLife],
  },
  {
    id: 2,
    jpg: jpgs['SL_02'],
    webp: webps['SL_02'],
    description: `Život teče`,
    tags: [t.images.categories.streetLife],
  },
  {
    id: 3,
    jpg: jpgs['SL_03'],
    webp: webps['SL_03'],
    description: `Vodim te`,
    tags: [t.images.categories.streetLife],
  },
  {
    id: 4,
    jpg: jpgs['SL_04'],
    webp: webps['SL_04'],
    description: `Corona time`,
    tags: [t.images.categories.streetLife],
  },
  {
    id: 5,
    jpg: jpgs['SL_05'],
    webp: webps['SL_05'],
    description: `City life`,
    tags: [t.images.categories.streetLife],
  },
  {
    id: 6,
    jpg: jpgs['SL_06'],
    webp: webps['SL_06'],
    description: `View`,
    tags: [t.images.categories.streetLife],
  },
  {
    id: 7,
    jpg: jpgs['SL_07'],
    webp: webps['SL_07'],
    description: `Dancing on the street`,
    tags: [t.images.categories.streetLife],
  },
  {
    id: 8,
    jpg: jpgs['SL_08'],
    webp: webps['SL_08'],
    description: `Corona time II`,
    tags: [t.images.categories.streetLife],
  },
  {
    id: 9,
    jpg: jpgs['SL_09'],
    webp: webps['SL_09'],
    description: `Ruke`,
    tags: [t.images.categories.streetLife],
  },
  {
    id: 10,
    jpg: jpgs['SL_10'],
    webp: webps['SL_10'],
    description: `On the street`,
    tags: [t.images.categories.streetLife],
  },
  {
    id: 11,
    jpg: jpgs['SL_11'],
    webp: webps['SL_11'],
    description: `Život mi nije lak`,
    tags: [t.images.categories.streetLife],
  },
  {
    id: 12,
    jpg: jpgs['SL_12'],
    webp: webps['SL_12'],
    description: `Točkovi života`,
    tags: [t.images.categories.streetLife],
  },
  {
    id: 13,
    jpg: jpgs['SL_13'],
    webp: webps['SL_13'],
    description: `Porodica`,
    tags: [t.images.categories.streetLife],
  },
  {
    id: 14,
    jpg: jpgs['SL_14'],
    webp: webps['SL_14'],
    description: `Lines`,
    tags: [t.images.categories.streetLife],
  },
  {
    id: 15,
    jpg: jpgs['SL_15'],
    webp: webps['SL_15'],
    description: `Vreme je novac`,
    tags: [t.images.categories.streetLife],
  },
  {
    id: 16,
    jpg: jpgs['SL_20'],
    webp: webps['SL_20'],
    description: `Gradska teškoba`,
    tags: [t.images.categories.streetLife],
  },
  {
    id: 17,
    jpg: jpgs['SL_21'],
    webp: webps['SL_21'],
    description: `Ne brže od života`,
    tags: [t.images.categories.streetLife],
  },
  {
    id: 18,
    jpg: jpgs['SL_22'],
    webp: webps['SL_22'],
    description: `NS time`,
    tags: [t.images.categories.streetLife],
  },
  {
    id: 19,
    jpg: jpgs['SL_23'],
    webp: webps['SL_23'],
    description: `Čekam`,
    tags: [t.images.categories.streetLife],
  },
  {
    id: 20,
    jpg: jpgs['SL_24'],
    webp: webps['SL_24'],
    description: `Naši heroji`,
    tags: [t.images.categories.streetLife],
  },
  {
    id: 21,
    jpg: jpgs['SL_25'],
    webp: webps['SL_25'],
    description: `Tačno na vreme`,
    tags: [t.images.categories.streetLife],
  },
  {
    id: 22,
    jpg: jpgs['SL_26'],
    webp: webps['SL_26'],
    description: `Veliki transport`,
    tags: [t.images.categories.streetLife],
  },
]
