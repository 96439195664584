import React from 'react'

export const Logo = () => (
  <svg
    width="246px"
    height="313px"
    viewBox="0 0 246 313"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Logo-loader-2-Copy" transform="translate(-287.000000, -174.000000)" fill="#C3EF2B">
        <g id="Manu" transform="translate(172.000000, 0.000000)">
          <g id="pic/logo">
            <g className="Logo" id="Logo" transform="translate(115.000000, 174.000000)">
              <g id="V" transform="translate(0.000000, 66.832740)">
                <polygon
                  id="Path-11"
                  points="129.709091 0 64.8545455 111.3879 0 0 51.9954545 0 64.8545455 21.7206406 77.1545455 0"
                ></polygon>
              </g>
              <g id="J">
                <polygon
                  id="Combined-Shape"
                  points="155.483182 66.2758007 155.427273 66.2758007 64.8545455 222.218861 25.9541284 155.374101 0 200.498221 64.8545455 312.44306 206.777622 67.3896797 206.863636 67.3896797 246 0.556939502 115.100917 0.556939502 89.146789 45.1120996 167.168182 45.1120996"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
