declare const TweenMax: any

import { inViewport } from '../../utils/in-viewport'
import GridFx from '../../grid-fx'
import { getCurrentRoute } from '../../lib/router'
import GridItem from '../../grid-item'
import { calcWinsize } from '../../utils/window-size'

// @TODO: move to [React](https://greensock.com/react)
// Looping the grid photos.
export function startLoop(self: GridFx) {
  const loopInterval = 500
  // Hide main content and show grid content.
  self.setCurrentContent('photography')
  // Get the items that are in the viewport.
  // Also, set the transform so that the photos are centered.
  const inViewportItems: GridItem[] = []
  for (const item of self.items) {
    const winsize = calcWinsize()
    if (!item.DOM.wrap) return
    if (inViewport(item.DOM.wrap, winsize)) {
      item.calcDimentions()
      const itemRect = item.rects.wrap
      if (!itemRect) return
      const ratioWrap = itemRect.height / itemRect.width
      const ratioWindow = winsize.height / winsize.width
      const scaleVal =
        ratioWrap > ratioWindow ? winsize.width / itemRect.width : winsize.height / itemRect.height
      TweenMax.set(item.DOM.wrap, {
        x: winsize.width / 2 - (itemRect.left + itemRect.width / 2),
        y: winsize.height / 2 - (itemRect.top + itemRect.height / 2),
        scale: scaleVal,
      })
      inViewportItems.push(item)
    }
  }

  if (inViewportItems.length > 1) {
    let oldpos = -1
    const loop = (pos = 0) => {
      // Cache the previous item.
      if (oldpos >= 0) {
        const oldel = inViewportItems[oldpos]
        // Reset.
        TweenMax.set(oldel.DOM.wrap, {
          opacity: 0,
          zIndex: 1,
        })
      }
      self.currentItem = inViewportItems[pos]
      // Fade in current visible item for the first iteration in the loop.
      if (oldpos === -1) {
        TweenMax.set(self.currentItem.DOM.wrap, { zIndex: 998 })
        TweenMax.to(self.currentItem.DOM.wrap, 0.2, { opacity: 1 })
      } else {
        TweenMax.set(self.currentItem.DOM.wrap, { opacity: 1, zIndex: 998 })
      }
      oldpos = pos
      pos = pos < inViewportItems.length - 1 ? pos + 1 : 0
      self.looptimeout = setTimeout(() => loop(pos), loopInterval)
    }
    loop()
  }
}

export function stopLoop(self: GridFx) {
  // Hide grid content and show main content.
  self.setCurrentContent(getCurrentRoute())

  // Stop the loop.
  clearTimeout(self.looptimeout)

  for (const item of self.items) {
    if (item == self.currentItem) {
      // Fade out current visible item.
      TweenMax.to(item.DOM.wrap, 0.2, {
        opacity: 0,
        // Reset.
        onComplete: () => TweenMax.set(item.DOM.wrap, { x: 0, y: 0, scale: 1 }),
      })
    } else {
      // Reset.
      TweenMax.set(item.DOM.wrap, { opacity: 0, x: 0, y: 0, scale: 1 })
    }
  }
}
