import BezierEasing from 'bezier-easing'
import { randNumber } from '../../utils/random-number'
import { lineEq } from '../../utils/line-eq'
import GridFx from '../../grid-fx'
import { Pages, getCurrentRoute } from '../../lib/router'
import { calcWinsize } from '../../utils/window-size'

// @TODO: move to [React](https://greensock.com/react)
export function showPhotography(self: GridFx) {
  clearTimeout(self.looptimeout)
  // Hide main content and show grid content.
  // self.setCurrentContent('photography')
  const winsize = calcWinsize()

  // Calculate if the current item is on the left or right side.
  // This will determine the movement for the other items.
  let side = 'left'
  if (self.currentItem) {
    const itemRect = self.currentItem.rects.el
    // Check if the item is on the left or right side of the viewport.
    // This will determine the direction for the animations of the grid items.
    side = itemRect && itemRect.left + itemRect.width / 2 < winsize.width / 2 ? 'left' : 'right'
  }

  for (const item of self.items) {
    let duration
    const ease = new Ease(BezierEasing(0.2, 1, 0.3, 1))

    // Scale down to its grid position.
    if (self.currentItem == item) {
      duration = 0.8
      // Animate the current visible item to its grid position.
      TweenMax.to(item.DOM.wrap, duration, {
        ease,
        x: 0,
        y: 0,
        scale: 1,
        opacity: 1,
        onComplete: () => TweenMax.set(item.DOM.wrap, { zIndex: 1 }),
      })
    } else {
      // Animate all the other grid items.
      // The duration and initial positions will depend on the distance from their center
      // to the boundaries of the viewport.
      const itemRect = item.rects.el
      if (!itemRect) return
      duration =
        lineEq(
          side === 'right' ? 7 : 14,
          side === 'right' ? 14 : 7,
          winsize.width,
          0,
          itemRect.left + itemRect.width / 2,
        ) / 10
      TweenMax.to(item.DOM.wrap, duration, {
        ease,
        startAt: {
          x:
            (side === 'left' ? 1 : -1) *
            lineEq(
              side === 'right' ? 100 : winsize.width,
              side === 'right' ? winsize.width : 100,
              winsize.width,
              0,
              itemRect.left + itemRect.width / 2,
            ),
          y:
            itemRect.top + itemRect.height / 2 < winsize.height / 2
              ? randNumber(-400, -200)
              : randNumber(200, 400),
          scale: 1,
          opacity: 1,
        },
        x: 0,
        y: 0,
        opacity: 1,
      })
    }

    // Animate title
    TweenMax.to(item.DOM.title, duration, {
      ease,
      startAt: { x: side === 'left' ? 500 : -500, opacity: 0 },
      x: 0,
      opacity: 1,
    })
  }

  const ease = new Ease(BezierEasing(0.2, 1, 0.3, 1))

  // if user is on photography page, it shouldn't animate again on click

  // Animate the left panel (categories) in.
  TweenMax.to(self.DOM.content?.category, 1, {
    ease,
    startAt:
      getCurrentRoute() === 'photography'
        ? {}
        : {
            x: -50,
            opacity: 0,
          },
    x: 0,
    opacity: 1,
  })
}

export function hidePhotography(self: GridFx, load: Pages) {
  const duration = 0.5
  const ease = new Ease(BezierEasing(0.2, 1, 0.3, 1))

  // Animate the left panel (categories) out.
  TweenMax.to(self.DOM.content?.category, duration, {
    ease,
    x: -50,
    opacity: 0,
  })

  // Animate the grid items out.
  for (const item of self.items) {
    TweenMax.to([item.DOM.wrap, item.DOM.title], duration, {
      ease,
      x: -50,
      opacity: 0,
      // Reset transform.
      onComplete: () => TweenMax.set(item.DOM.wrap, { x: 0 }),
    })
  }
  loadAnotherPage(self, load, { duration, ease })
}

// Slight animation from right to left
function loadAnotherPage(self: GridFx, page: Pages, { duration, ease }: any) {
  if (page === '') return
  if (page === getCurrentRoute()) return
  // Show home/content
  TweenMax.to(self.DOM.content && self.DOM.content[page], duration, {
    ease,
    startAt: { x: 50, opacity: 0 },
    x: 0,
    opacity: 1,
  })
}
