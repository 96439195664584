import React from 'react'
import { Link } from '../lib/router'
import { Logo } from './logo/logo'
import { t } from '../data/translations'

export const Header = () => {
  return (
    <div className="header">
      <h2 className="title">
        <Link to="/">
          <i className="logo">
            <Logo />
          </i>
          {t.meta.name}
        </Link>
      </h2>
      <nav className="menu">
        {items.map((item) => {
          return (
            <Link key={item.name} to={item.to} className={`menu__item`}>
              {item.name}
            </Link>
          )
        })}
      </nav>
    </div>
  )
}

export const items = [
  {
    name: t.menu.photography,
    to: '/photography',
  },
  {
    name: t.menu.contact,
    to: '/contact',
  },
]
