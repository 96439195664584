import React from 'react'
import { t } from '../data/translations'

export const About = () => {
  return (
    <section className="content content--about content--current">
      <div className="content__text">
        <p
          className="content__paragraph"
          dangerouslySetInnerHTML={{ __html: t.pages.home.description }}
        />
      </div>
    </section>
  )
}
