import React from 'react'

export const pages = ['', 'about', 'photography', 'contact'] as const
export type Pages = typeof pages[number]

const hashToRouteMapper = (hash: string): Pages => {
  const cleanHash: Pages = hash.replace(/(#|\/)/g, '') as any
  switch (cleanHash) {
    case '':
      return 'about'
    case 'contact':
      return 'contact'
    case 'photography':
      return 'photography'
    default:
      throw console.error('Never comes here')
  }
}

interface ILink {
  children: React.ReactNode
  to: string
  className?: string
}

export const Link = (props: ILink) => {
  return (
    <a
      className={props.className && `${props.className} ${props.className}--${getRoute(props.to)}`}
      href={`#${props.to}`}
    >
      {props.children}
    </a>
  )
}

const getRoute = (hash: string) => {
  const route = hashToRouteMapper(hash)
  const index = pages.indexOf(route)
  if (index === -1) {
    throw console.warn(`The route ${hash} is not configured!`)
  }
  return pages[index]
}

export const getCurrentRoute = () => {
  return getRoute(location.hash)
}

export const isRoute = (route: Pages) => {
  return getCurrentRoute() === route
}
