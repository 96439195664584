export const imagesLoaded = (elements: NodeListOf<HTMLImageElement>) => {
  const length = elements.length
  let counter = 0

  return new Promise((resolve, reject) => {
    if (length < 1) resolve()

    const incrementCounter = () => {
      counter++
      if (counter === length) {
        resolve()
      }
    }

    elements.forEach((img) => {
      if (img.complete) {
        incrementCounter()
      } else {
        img.addEventListener('load', incrementCounter, false)
        img.addEventListener('error', reject, false)
      }

      if (counter === length) {
        resolve()
      }
    })
  })
}
