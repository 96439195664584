import React from 'react'

export const useHashChange = () => {
  const [hash, setHash] = React.useState(window.location.hash)

  const listenToHashChange = (e: PopStateEvent) => {
    e.preventDefault()
    setHash(window.location.hash)
  }

  React.useEffect(() => {
    window.addEventListener('hashchange', listenToHashChange as EventListener)
    return () => window.removeEventListener('hashchange', listenToHashChange as EventListener)
  }, [])

  return { hash }
}
