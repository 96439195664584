import { getByTag } from './images'
import { objectKeys } from '../utils/object-keys'
import { t } from './translations'

export function getById(id: number) {
  return Categories.find((cat) => cat.id === id)
}

const categoryList = objectKeys(t.images.categories).map((k) => t.images.categories[k])

export const Categories = categoryList.map((cat, i) => ({
  id: i,
  name: cat,
  count: getByTag(cat).length,
}))
