import jpg from '../../../img/jpg/*.jpg'
import webp from '../../../img/webp/*.webp'

import { t } from '../translations'

const jpgs: Record<string, string> = jpg as any
const webps: Record<string, string> = webp as any

export const architecture = [
  {
    id: 1,
    jpg: jpgs['AR_01'],
    webp: webps['AR_01'],
    description: `Linije`,
    tags: [t.images.categories.architecture],
  },
  {
    id: 2,
    jpg: jpgs['AR_02'],
    webp: webps['AR_02'],
    description: `Sukob`,
    tags: [t.images.categories.architecture],
  },
  {
    id: 3,
    jpg: jpgs['AR_03'],
    webp: webps['AR_03'],
    description: `Ugao`,
    tags: [t.images.categories.architecture],
  },
  {
    id: 4,
    jpg: jpgs['AR_04'],
    webp: webps['AR_04'],
    description: `Kontrast`,
    tags: [t.images.categories.architecture],
  },
  {
    id: 5,
    jpg: jpgs['AR_05'],
    webp: webps['AR_05'],
    description: `Let`,
    tags: [t.images.categories.architecture],
  },
  {
    id: 6,
    jpg: jpgs['AR_06'],
    webp: webps['AR_06'],
    description: `Na mostu`,
    tags: [t.images.categories.architecture],
  },
  {
    id: 7,
    jpg: jpgs['AR_07'],
    webp: webps['AR_07'],
    description: `Sudar`,
    tags: [t.images.categories.architecture],
  },
  {
    id: 8,
    jpg: jpgs['AR_08'],
    webp: webps['AR_08'],
    description: `Biću svetionik`,
    tags: [t.images.categories.architecture],
  },
  {
    id: 9,
    jpg: jpgs['AR_09'],
    webp: webps['AR_09'],
    description: `Sukob II`,
    tags: [t.images.categories.architecture],
  },
  {
    id: 10,
    jpg: jpgs['AR_10'],
    webp: webps['AR_10'],
    description: `Komšinice`,
    tags: [t.images.categories.architecture],
  },
  {
    id: 11,
    jpg: jpgs['AR_11'],
    webp: webps['AR_11'],
    description: `Ka vrhu`,
    tags: [t.images.categories.architecture],
  },
  {
    id: 12,
    jpg: jpgs['AR_12'],
    webp: webps['AR_12'],
    description: `Ključaonica`,
    tags: [t.images.categories.architecture],
  },
  {
    id: 13,
    jpg: jpgs['AR_20'],
    webp: webps['AR_20'],
    description: `Epicentar`,
    tags: [t.images.categories.architecture],
  },
  {
    id: 14,
    jpg: jpgs['AR_21'],
    webp: webps['AR_21'],
    description: `Ključaonica`,
    tags: [t.images.categories.architecture],
  },
  {
    id: 15,
    jpg: jpgs['AR_22'],
    webp: webps['AR_22'],
    description: `Pramac`,
    tags: [t.images.categories.architecture],
  },
  {
    id: 16,
    jpg: jpgs['AR_23'],
    webp: webps['AR_23'],
    description: `Lines`,
    tags: [t.images.categories.architecture],
  },
]
