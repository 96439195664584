import React from 'react'
import '../style/form.css'
import { t } from '../data/translations'
import { isRoute } from '../lib/router'
import { useHashChange } from '../lib/use-router'

export const Contact = () => {
  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [isContactPage, setContactPage] = React.useState(false)
  const { hash } = useHashChange()

  React.useEffect(() => setContactPage(isRoute('contact')), [hash])

  return (
    <section className="content content--contact">
      <h2 className="content--contact-headline">{t.pages.contact.title}</h2>

      <form name="contact" method="post">
        <input type="hidden" name="form-name" value="contact" />
        <span className={`input input--wave${name.length > 0 ? ' input--filled' : ''}`}>
          <input
            className={`input__field input__field--wave`}
            name="name"
            type="text"
            autoComplete="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            minLength={2}
            required
            tabIndex={isContactPage ? 0 : -1}
          />
          <label className="input__label input__label--wave">
            <span
              className="input__label-content input__label-content--wave"
              data-content={t.pages.contact.form.name}
            >
              {t.pages.contact.form.name}
            </span>
          </label>
          <svg
            className="graphic graphic--wave"
            width="300%"
            height="100%"
            viewBox="0 0 1200 60"
            preserveAspectRatio="none"
          >
            <path d="M1200,9c0,0-305.005,0-401.001,0C733,9,675.327,4.969,598,4.969C514.994,4.969,449.336,9,400.333,9C299.666,9,0,9,0,9v43c0,0,299.666,0,400.333,0c49.002,0,114.66,3.484,197.667,3.484c77.327,0,135-3.484,200.999-3.484C894.995,52,1200,52,1200,52V9z" />
          </svg>
        </span>
        <span className={`input input--wave${email.length > 0 ? ' input--filled' : ''}`}>
          <input
            className="input__field input__field--wave"
            name="email"
            type="email"
            id="input-8"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            minLength={6}
            required
            tabIndex={isContactPage ? 0 : -1}
          />
          <label className="input__label input__label--wave">
            <span
              className="input__label-content input__label-content--wave"
              data-content={t.pages.contact.form.email}
            >
              {t.pages.contact.form.email}
            </span>
          </label>
          <svg
            className="graphic graphic--wave"
            width="300%"
            height="100%"
            viewBox="0 0 1200 60"
            preserveAspectRatio="none"
          >
            <path d="M1200,9c0,0-305.005,0-401.001,0C733,9,675.327,4.969,598,4.969C514.994,4.969,449.336,9,400.333,9C299.666,9,0,9,0,9v43c0,0,299.666,0,400.333,0c49.002,0,114.66,3.484,197.667,3.484c77.327,0,135-3.484,200.999-3.484C894.995,52,1200,52,1200,52V9z" />
          </svg>
        </span>
        <span className={`input textarea input--wave${message.length > 0 ? ' input--filled' : ''}`}>
          <textarea
            className="input__field input__field--wave"
            name="message"
            id="input-9"
            autoComplete="new-password"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            tabIndex={isContactPage ? 0 : -1}
          />
          <label className="input__label input__label--wave">
            <span
              className="input__label-content input__label-content--wave"
              data-content={t.pages.contact.form.message}
            >
              {t.pages.contact.form.message}
            </span>
          </label>
          <svg
            className="graphic graphic--wave"
            width="300%"
            height="100%"
            viewBox="0 0 1200 400"
            preserveAspectRatio="none"
          >
            <path d="m1200,35.81216c0,0 -305.005,0 -401.001,0c-65.999,0 -123.672,-30.84316 -200.999,-30.84316c-83.006,0 -148.664,30.84316 -197.667,30.84316c-100.667,0 -400.333,0 -400.333,0l0,329.01406c0,0 299.666,0 400.333,0c49.002,0 114.66,26.65779 197.667,26.65779c77.327,0 135,-26.65779 200.999,-26.65779c95.996,0 401.001,0 401.001,0l0,-329.01406z" />
          </svg>
        </span>
        <span className="submit-button">
          <button tabIndex={isContactPage ? 0 : -1} type="submit">
            {t.pages.contact.form.send}
          </button>
        </span>
      </form>
    </section>
  )
}
