import jpg from '../../../img/jpg/*.jpg'
import webp from '../../../img/webp/*.webp'

import { t } from '../translations'

const jpgs: Record<string, string> = jpg as any
const webps: Record<string, string> = webp as any

export const blackWhite = [
  {
    id: 1,
    jpg: jpgs['BW_01'],
    webp: webps['BW_01'],
    description: `Na pravom putu`,
    tags: [t.images.categories['black-white']],
  },
  {
    id: 2,
    jpg: jpgs['BW_02'],
    webp: webps['BW_02'],
    description: `Lek`,
    tags: [t.images.categories['black-white']],
  },
  {
    id: 3,
    jpg: jpgs['BW_03'],
    webp: webps['BW_03'],
    description: `One man show`,
    tags: [t.images.categories['black-white']],
  },
  {
    id: 4,
    jpg: jpgs['BW_04'],
    webp: webps['BW_04'],
    description: `Da`,
    tags: [t.images.categories['black-white']],
  },
  {
    id: 5,
    jpg: jpgs['BW_05'],
    webp: webps['BW_05'],
    description: `Plovim`,
    tags: [t.images.categories['black-white']],
  },
  {
    id: 6,
    jpg: jpgs['BW_06'],
    webp: webps['BW_06'],
    description: `Rađanje čvarka`,
    tags: [t.images.categories['black-white']],
  },
  {
    id: 7,
    jpg: jpgs['BW_07'],
    webp: webps['BW_07'],
    description: `Stolica`,
    tags: [t.images.categories['black-white']],
  },
  {
    id: 8,
    jpg: jpgs['BW_08'],
    webp: webps['BW_08'],
    description: `Power`,
    tags: [t.images.categories['black-white']],
  },
  {
    id: 9,
    jpg: jpgs['BW_09'],
    webp: webps['BW_09'],
    description: `Run`,
    tags: [t.images.categories['black-white']],
  },
  {
    id: 10,
    jpg: jpgs['BW_10'],
    webp: webps['BW_10'],
    description: `Fotograf`,
    tags: [t.images.categories['black-white']],
  },
  {
    id: 11,
    jpg: jpgs['BW_11'],
    webp: webps['BW_11'],
    description: `I bi svetlo`,
    tags: [t.images.categories['black-white']],
  },
  {
    id: 12,
    jpg: jpgs['BW_12'],
    webp: webps['BW_12'],
    description: `U ramu života`,
    tags: [t.images.categories['black-white']],
  },
  {
    id: 13,
    jpg: jpgs['BW_13'],
    webp: webps['BW_13'],
    description: `Životni krug`,
    tags: [t.images.categories['black-white']],
  },
  {
    id: 14,
    jpg: jpgs['BW_14'],
    webp: webps['BW_14'],
    description: `Hrana`,
    tags: [t.images.categories['black-white']],
  },
  {
    id: 15,
    jpg: jpgs['BW_20'],
    webp: webps['BW_20'],
    description: `Odušak`,
    tags: [t.images.categories['black-white']],
  },
  {
    id: 16,
    jpg: jpgs['BW_21'],
    webp: webps['BW_21'],
    description: `A život teče`,
    tags: [t.images.categories['black-white']],
  },
  {
    id: 17,
    jpg: jpgs['BW_22'],
    webp: webps['BW_22'],
    description: `Molitva`,
    tags: [t.images.categories['black-white']],
  },
  {
    id: 18,
    jpg: jpgs['BW_23'],
    webp: webps['BW_23'],
    description: `Ljubav`,
    tags: [t.images.categories['black-white']],
  },
  {
    id: 19,
    jpg: jpgs['BW_24'],
    webp: webps['BW_24'],
    description: `Bogatstvo`,
    tags: [t.images.categories['black-white']],
  },
  {
    id: 20,
    jpg: jpgs['BW_25'],
    webp: webps['BW_25'],
    description: `Samovanje`,
    tags: [t.images.categories['black-white']],
  },
  {
    id: 21,
    jpg: jpgs['BW_26'],
    webp: webps['BW_26'],
    description: `Poverenje`,
    tags: [t.images.categories['black-white']],
  },
]
