export const isFullScreenMode =
  (document as any).fullScreen ||
  (document as any).mozFullScreen ||
  (document as any).webkitIsFullScreen

export function openFullscreen(elem: Element) {
  if (!elem) return

  if (elem.requestFullscreen) {
    elem.requestFullscreen()
  } else if ((elem as any).mozRequestFullScreen) {
    /* Firefox */
    ;(elem as any).mozRequestFullScreen()
  } else if ((elem as any).webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    ;(elem as any).webkitRequestFullscreen()
  } else if ((elem as any).msRequestFullscreen) {
    /* IE/Edge */
    ;(elem as any).msRequestFullscreen()
  }
}

export function closeFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  } else if ((document as any).mozCancelFullScreen) {
    /* Firefox */
    ;(document as any).mozCancelFullScreen()
  } else if ((document as any).webkitExitFullscreen) {
    /* Chrome, Safari and Opera */
    ;(document as any).webkitExitFullscreen()
  } else if ((document as any).msExitFullscreen) {
    /* IE/Edge */
    ;(document as any).msExitFullscreen()
  }
}
