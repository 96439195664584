import jpg from '../../../img/jpg/*.jpg'
import webp from '../../../img/webp/*.webp'

import { t } from '../translations'

const jpgs: Record<string, string> = jpg as any
const webps: Record<string, string> = webp as any

export const nature = [
  {
    id: 1,
    jpg: jpgs['NA_01'],
    webp: webps['NA_01'],
    description: `Ledeni start`,
    tags: [t.images.categories.nature],
  },
  {
    id: 2,
    jpg: jpgs['NA_02'],
    webp: webps['NA_02'],
    description: `One`,
    tags: [t.images.categories.nature],
  },
  {
    id: 3,
    jpg: jpgs['NA_03'],
    webp: webps['NA_03'],
    description: `Borba titana`,
    tags: [t.images.categories.nature],
  },
  {
    id: 4,
    jpg: jpgs['NA_04'],
    webp: webps['NA_04'],
    description: `Magija`,
    tags: [t.images.categories.nature],
  },
  {
    id: 5,
    jpg: jpgs['NA_05'],
    webp: webps['NA_05'],
    description: `Jutarnja čarolija`,
    tags: [t.images.categories.nature],
  },
  {
    id: 6,
    jpg: jpgs['NA_06'],
    webp: webps['NA_06'],
    description: `Dubina Panonske zime`,
    tags: [t.images.categories.nature],
  },
  {
    id: 7,
    jpg: jpgs['NA_07'],
    webp: webps['NA_07'],
    description: `Tamo daleko`,
    tags: [t.images.categories.nature],
  },
  {
    id: 8,
    jpg: jpgs['NA_08'],
    webp: webps['NA_08'],
    description: `Jutro`,
    tags: [t.images.categories.nature],
  },
  {
    id: 9,
    jpg: jpgs['NA_09'],
    webp: webps['NA_09'],
    description: `Mene gledaš?`,
    tags: [t.images.categories.nature],
  },
  {
    id: 10,
    jpg: jpgs['NA_10'],
    webp: webps['NA_10'],
    description: `Planeta Zemlja`,
    tags: [t.images.categories.nature],
  },
  {
    id: 11,
    jpg: jpgs['NA_11'],
    webp: webps['NA_11'],
    description: `Praskozorje`,
    tags: [t.images.categories.nature],
  },
  {
    id: 12,
    jpg: jpgs['NA_12'],
    webp: webps['NA_12'],
    description: `Bel' konj`,
    tags: [t.images.categories.nature],
  },
  {
    id: 13,
    jpg: jpgs['NA_13'],
    webp: webps['NA_13'],
    description: `Jesen u mom kraju`,
    tags: [t.images.categories.nature],
  },
  {
    id: 14,
    jpg: jpgs['NA_14'],
    webp: webps['NA_14'],
    description: `I na nebu i na zemlji`,
    tags: [t.images.categories.nature],
  },
  {
    id: 15,
    jpg: jpgs['NA_15'],
    webp: webps['NA_15'],
    description: `Svetlolov`,
    tags: [t.images.categories.nature],
  },
  {
    id: 16,
    jpg: jpgs['NA_16'],
    webp: webps['NA_16'],
    description: `Vojvodina`,
    tags: [t.images.categories.nature],
  },
  {
    id: 17,
    jpg: jpgs['NA_20'],
    webp: webps['NA_20'],
    description: `Sam`,
    tags: [t.images.categories.nature],
  },
  {
    id: 18,
    jpg: jpgs['NA_21'],
    webp: webps['NA_21'],
    description: `Čerobno jutro`,
    tags: [t.images.categories.nature],
  },
  {
    id: 19,
    jpg: jpgs['NA_22'],
    webp: webps['NA_22'],
    description: `Govor tišine`,
    tags: [t.images.categories.nature],
  },
  {
    id: 20,
    jpg: jpgs['NA_23'],
    webp: webps['NA_23'],
    description: `Ja sam za ples`,
    tags: [t.images.categories.nature],
  },
  {
    id: 21,
    jpg: jpgs['NA_24'],
    webp: webps['NA_24'],
    description: `O`,
    tags: [t.images.categories.nature],
  },
  {
    id: 22,
    jpg: jpgs['NA_25'],
    webp: webps['NA_25'],
    description: `Sasvim prirodno`,
    tags: [t.images.categories.nature],
  },
]
