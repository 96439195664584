/**
 * cols 1 / 16
 * rows 1 / 36
 *
 * y0 / x0 / y1 / x1
 */
export const gridArea = (index: number, base = 15) => {
  const times = Math.floor(index / base) * 35
  const mod = index % base

  switch (mod) {
    case 0:
      return `${1 + times} / 1 / ${6 + times} / 9`
    case 1:
      return `${2 + times} / 9 / ${6 + times} / 16`
    case 2:
      return `${6 + times} / 1 / ${9 + times} / 7`
    case 3:
      return `${6 + times} / 7 / ${9 + times} / 16`
    case 4:
      return `${9 + times} / 1 / ${11 + times} / 6`
    case 5:
      return `${9 + times} / 6 / ${14 + times} / 16`
    case 6:
      return `${11 + times} / 1 / ${16 + times} / 6`
    case 7:
      return `${14 + times} / 7 / ${19 + times} / 16`
    case 8:
      return `${16 + times} / 1 / ${22 + times} / 7`
    case 9:
      return `${19 + times} / 7 / ${25 + times} / 16`
    case 10:
      return `${25 + times} / 1 / ${22 + times} / 7`
    case 11:
      return `${30 + times} / 1 / ${25 + times} / 16`
    case 12:
      return `${33 + times} / 1 / ${30 + times} / 8`
    case 13:
      return `${36 + times} / 8 / ${30 + times} / 16`
    case 14:
      return `${36 + times} / 1 / ${33 + times} / 8`

    default:
      break
  }
}

/**
 * cols 1 / 16
 * rows 1 / 36
 *
 * y0 / x0 / y1 / x1
 */
export const gridAreaDesktop = (index: number, base = 15) => {
  const times = Math.floor(index / base) * 35
  const mod = index % base

  switch (mod) {
    case 0:
      return `${1 + times} / 1 / ${5 + times} / 9`
    case 1:
      return `${2 + times} / 9 / ${5 + times} / 16`
    case 2:
      return `${5 + times} / 1 / ${8 + times} / 6`
    case 3:
      return `${5 + times} / 6 / ${9 + times} / 14`
    case 4:
      return `${8 + times} / 1 / ${11 + times} / 6`
    case 5:
      return `${9 + times} / 6 / ${14 + times} / 16`
    case 6:
      return `${11 + times} / 1 / ${16 + times} / 6`
    case 7:
      return `${14 + times} / 6 / ${19 + times} / 15`
    case 8:
      return `${16 + times} / 1 / ${22 + times} / 6`
    case 9:
      return `${19 + times} / 6 / ${25 + times} / 14`
    case 10:
      return `${25 + times} / 1 / ${22 + times} / 6`
    case 11:
      return `${30 + times} / 1 / ${25 + times} / 15`
    case 12:
      return `${33 + times} / 1 / ${30 + times} / 8`
    case 13:
      return `${30 + times} / 16 / ${33 + times} / 8`
    case 14:
      return `${33 + times} / 1 / ${36 + times} / 16`

    default:
      break
  }
}
