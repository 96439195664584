import GridItem from './grid-item'
import { showPhotography, hidePhotography } from './pages/photography/visibility'
import { startLoop, stopLoop } from './pages/photography/loop'
import { pages, Pages, getCurrentRoute, isRoute } from './lib/router'

// @TODO: move to [React](https://greensock.com/react)
// Section - Animating Route Changes
// The grid obj.
export default class GridFx {
  DOM: {
    content: {
      about: HTMLDivElement | null
      photography: HTMLDivElement | null
      contact: HTMLDivElement | null
      category: HTMLDivElement | null
      grid: HTMLDivElement | null
    }
    elements: {
      homeCtrl: HTMLDivElement | null
      showPhotosCtrl: HTMLDivElement | null
      showContactCtrl: HTMLDivElement | null
    }
  }
  items: GridItem[]
  mousetime: any
  currentItem: GridItem | null
  looptimeout: any

  constructor(grid: HTMLDivElement) {
    this.DOM = {
      content: {
        about: null,
        photography: null,
        contact: null,
        category: null,
        grid,
      },
      elements: {
        homeCtrl: null,
        showPhotosCtrl: null,
        showContactCtrl: null,
      },
    }
    // The grid items instances.
    this.items = Array.from(this.DOM.content.grid?.querySelectorAll('.grid__item') || []).map(
      (item) => new GridItem(item as any),
    )

    // The content elements.
    this.DOM.content = {
      about: document.querySelector('.content--about'),
      photography: document.querySelector('.content--photography'),
      contact: document.querySelector('.content--contact'),
      category: document.querySelector('.content--category'),
      grid: document.querySelector('.content--grid'),
    }
    // Other elements
    this.DOM.elements = {
      homeCtrl: document.querySelector('h2.title a'),
      showPhotosCtrl: document.querySelector('nav.menu > .menu__item--photography'),
      showContactCtrl: document.querySelector('nav.menu > .menu__item--contact'),
    }
    this.currentItem = null
    // Bind events.
    this.initEvents()
  }

  initEvents() {
    const currentRoute = getCurrentRoute()

    if (currentRoute === 'photography') showPhotography(this)
    // Set visibility of initial route
    this.setCurrentContent(currentRoute)

    // fix listener below on OSX, for some reason mouseenter is being triggered on screen touch
    // catch before mouseenter is being fired
    this.DOM.elements.showPhotosCtrl?.addEventListener('touchend', (e) => {
      ;(e.target as any)?.click()
    })
    // Mouseenter: Start looping through the photos that are inside the viewport.
    // We only loop through these so that we can see the whole animation (photo scaling down to its grid position)
    this.DOM.elements.showPhotosCtrl?.addEventListener('mouseenter', () => {
      clearTimeout(this.mousetime)
      this.currentItem = null
      this.mousetime = setTimeout(() => {
        if (!isRoute('photography')) startLoop(this)
      }, 400)
    })
    // Mouseleave: stop the photo looping animation.
    this.DOM.elements.showPhotosCtrl?.addEventListener('mouseleave', () => {
      clearTimeout(this.mousetime)
      if (!isRoute('photography')) stopLoop(this)
    })
    // Click: stop the loop and show the grid.
    this.DOM.elements.showPhotosCtrl?.addEventListener('click', () => {
      this.setCurrentContent('photography')
      showPhotography(this)
    })
    this.DOM.elements.showContactCtrl?.addEventListener('click', () => {
      this.setCurrentContent('contact')
    })
    this.DOM.elements?.homeCtrl?.addEventListener('click', () => {
      this.setCurrentContent('about')
    })
  }

  setCurrentMenuItem(currentElement: Pages) {
    document.querySelectorAll('.menu__item--current').forEach((el) => {
      el.classList.remove('menu__item--current')
    })
    document.querySelectorAll('.content--current').forEach((el) => {
      el.classList.remove('content--current')
    })
    document.querySelector(`.menu__item--${currentElement}`)?.classList.add('menu__item--current')
    document.querySelector(`.content--${currentElement}`)?.classList.add('content--current')
  }

  setCurrentContent(show: Pages) {
    if (show !== 'photography') hidePhotography(this, show)
    pages.forEach((page) => {
      if (page === '') return
      const content = this.DOM.content?.[page]
      TweenMax.set(
        content,
        show === page ? { opacity: 1, height: '100%' } : { opacity: 0, height: 0 },
      )
      content?.classList[show === page ? 'add' : 'remove']('content--current')
    })
    this.setCurrentMenuItem(show)
  }
}
